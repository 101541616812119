import React from 'react'
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useState } from "react";
import { wrap } from "popmotion";
import { Carousel } from 'react-responsive-carousel';
import { motion, AnimatePresence } from "framer-motion";
import img1 from "../sliders/slider1.jpeg"
import img2 from "../sliders/slider2.jpeg"
import img3 from "../sliders/slider3.jpeg"
import img4 from "../sliders/slider4.jpeg"
import img5 from "../sliders/slider5.jpeg"
const images =[
  "../sliders/slider1.jpeg",
  "../sliders/slider2.jpeg",
  "../sliders/slider3.jpeg",
  "../sliders/slider4.jpeg",
  "../sliders/slider5.jpeg"
]
const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0
      };
    }
  };
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
const Imgsliders = () => {
    const [[page, direction], setPage] = useState([0, 0]);

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };
  return (
<AnimatePresence initial={false} custom={direction}>
    <Carousel infiniteLoop interval={3500} autoPlay={true} showIndicators={true} showThumbs={false} showStatus={false} swipeable={true} animationHandler="fade">
    <div> 
        <div className="flex-col col-6"><motion.img src={img1}/></div>
    </div>
    <div>
        <motion.img custom={direction}
        variants={variants} 
        initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }} src={img2}/>
        
    </div>
    <div>
        <motion.img src={img3} />
        
    </div>
    <div>
        <motion.img src={img4} />
        
    </div>
    <div>
        <motion.img src={img5} />
        
    </div>
    
</Carousel>
</AnimatePresence>
  )
}

export default Imgsliders