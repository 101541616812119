import React, {useRef} from 'react'
import { useScroll,useSpring,useTransform,MotionValue,useMotionValue,useMotionTemplate,LazyMotion,domAnimation, m } from "framer-motion"

import emailjs from '@emailjs/browser';

import {BsYoutube,BsFacebook,BsInstagram,BsLinkedin, BsWhatsapp } from "react-icons/bs";
//import Iframe from 'react-iframe'
function useParallax(value: MotionValue<10>, distance: 10) {
  return useTransform(value, [0, 1], [-distance, distance]);
}
const Contact = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ["0 1","1.33 1"] });
  const y = useParallax(scrollYProgress, 300);
  //const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 0,
    restDelta: 0.001
 });
 const shadowX = useSpring(1)
const shadowY = useMotionValue(1)
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5am0iu4",
        "template_d6b67bm",
        form.current,
        "rlVjgkVDEdu45omv0"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    };
  return (
    <LazyMotion className="mt-60" style={{scale: scaleX }} features={domAnimation}>
    <div className="w-full flex flex-col justify-center items-center mt-0">
      

    <div className="flex flex-col justify-center items-center lg:px-32 md:px-32 py-0 px-20">
      <h1 className="text-brightColor hidden xl:flex md:flex text-4xl font-bold text-center lg:pt-16  pb-0">
      Contact Us
      </h1>
      
      <div className=" align-item-center flex flex-col md:flex-row  ">
        <m.div ref={ref} style={{scale:0,opacity:1 }} className="m-0 p-10" InView={{ x: 10, opacity: 0 }}
                whileInView={{scale:1, x: 0, opacity: 1 }} 
                transition={{ delay: 0 }}
                exit={{
                  opacity: 0, 
                  transform: 'scale(1.5)', 
                  transition: { ease: 'easeIn', duration: 100 } 
                }} className="lg:bg-contain md:bg-cover sm:bg-cover flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col bg-grey-500 rounded-lg p-5 justify-center items-center sm:mt-32  md:py-0 lg:px-32 mb-0 md:mt-5">
        
        <h1 className="font-bold text-2xl text-center mt-5 py-2">
        Office
        </h1>
          <p className="text-xl text-left font-semibold py-2">Guru Kripa Consultants</p>
          <p className="text-xl text-center font-semibold text-justify py-2">A-18/D DDA Flats Sarai Rohilla, Saral Basti, Shastri Nagar, Onkar Nagar, West Delhi, Delhi 110035, India</p>          

          <a
              className=" hover:text-brightColor transition-all cursor-pointer font-semibold text-xl py-2"
              href="mailto:info@gkc.org.in"
            >
              info@gkc.org.in
            </a>
          <div className="text-xl py-2">
            <a
              className=" hover:text-brightColor transition-all cursor-pointer font-semibold"
              href="tel:+919873771014"
            >
              +91 9873771014
            </a>
          </div>
          <div className="flex flex-row text-1xl py-2 text-3xl m-2 ">
          
            <a
              className="px-2 hover:text-brightColor transition-all cursor-pointer"
              href="https://www.linkedin.com/company/guru-kripa-consultants/"
            ><BsLinkedin size={30} style={{ fill: '#0073b2' }} /></a>
            {/*<a
              className="px-2 hover:text-brightColor transition-all cursor-pointer"
              href=""
  ><BsTwitter size={30} style={{ fill: '#000000' }} /></a>*/}
             <a
              className="px-2 hover:text-brightColor transition-all cursor-pointer"
              href="https://wa.me/+919873771014"
            ><BsWhatsapp size={30} style={{ fill: '#55d763' }} /></a>
            

          </div>
        </m.div>
        <m.div ref={ref} style={{scale:0,opacity:1 }} className="m-0 p-10" InView={{ x: 10, opacity: 0 }}
                whileInView={{scale:1, x: 0, opacity: 1 }} 
                transition={{ delay: 0 }}
                exit={{
                  opacity: 0, 
                  transform: 'scale(1.5)', 
                  transition: { ease: 'easeIn', duration: 100 } 
                }} className="flex-row lg:flex-row md:flex-row sm:flex-col xs:flex-col bg-grey-500 min-h-screen justify-center items-center md:mt-10 sm:py-0 lg:px-32 pt-0">
          <form ref={form} onSubmit={sendEmail}>
            <h2 className="w-full text-2xl font-semibold text-center">
              Fill Form
            </h2>
            <div className="from-orange-600 text-center mt-5">
              <input
                className=" form-input px-4 py-2"
                name="name"
                type="text"
                placeholder="Name"
              />
            </div>
            <div className="from-orange-600 text-center mt-5">
              <input
                className=" form-input px-4 py-2"
                name="phone"
                type="number"
                placeholder="Phone"
              />
            </div>
            <div className="from-orange-600 text-center mt-5">
              <input
                className=" form-input px-4 py-2"
                name="email"
                type="text"
                placeholder="Email"
              />
            </div>
            <div className="from-orange-600 text-center mt-5">
              <textarea
                className=" form-textarea px-8 py-2"
                name="message"
                type="text"
                placeholder="Message"
              />
            </div>
            <div className="py-3 flex justify-center items-center text-center">
                <input className="form-input bg-blue-900 text-gray-200 font-medium px-5 py-3 cursor-pointer" name="submit" title="Submit" type="submit"
                value="Submit"/>
            </div>
          </form>
        </m.div>
      </div>
    </div>
    {/*<div className="w-full mb-5">
    <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3356.327132410045!2d74.83359187451732!3d32.73050318636361!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391e84f85f5a8d51%3A0x5ba4b622aca47ae3!2sVikas%20Lane%20No%201%2C%20Jammu%20180002!5e0!3m2!1sen!2sin!4v1699953563110!5m2!1sen!2sin" width="" height="350" allow="fullscreen" className="w-full border-2 border-cyan-900 shadow-gray-700 rounded-lg"></Iframe>
</div>*/}
  </div>
      </LazyMotion>
  );
};

export default Contact;
