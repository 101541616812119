import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../logos/EDP.jpg"
import img2 from "../logos/EH4200.jpg"
import img3 from "../logos/hvmech.jpg"
import img4 from "../logos/LR-Series-Liquid.jpg"
import img5 from "../logos/Nedc.jpg"
import img6 from "../logos/nes.jpeg"
import img7 from "../logos/R.jpg"
import img8 from "../logos/Stocks.jpg"
import img9 from "../logos/superZoom-EDS-480-Industrial-3-.png"
const responsive = {

    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const Logosliders = () => {
    return (
        <div>
    <h1 className="text-4xl text-center font-bold text-gray-800 pb-5">Industrial Vacuum Pumps/Systems Solution</h1>
  <Carousel responsive={responsive} infinite={true}
  autoPlay={true}
  autoPlaySpeed={1000} stopOnHover={false}>
    <div><img src={img1} /></div>
    <div><img src={img2} /></div>
    <div><img src={img3} /></div>
    <div><img src={img4} /></div>
    <div><img src={img5} /></div>
    <div><img src={img6} /></div>
    <div><img src={img7} /></div>
    <div><img src={img8} /></div>
    <div><img src={img9} /></div>
  </Carousel>
  </div>
   )
}

export default Logosliders