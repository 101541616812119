import React, {useRef} from 'react'
import { useScroll,useSpring,useTransform,MotionValue,useMotionValue,useMotionTemplate,LazyMotion,domAnimation, m } from "framer-motion"

const About = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ["0 1","1.33 1"] });

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 0,
    restDelta: 0.001
 });
  return (
    <LazyMotion className="mt-60" style={{scale: scaleX }} features={domAnimation}>

    <div>
      <m.div ref={ref} style={{scale:0,opacity:1 }} className="m-0 p-10" InView={{ x: 10, opacity: 0 }}
                whileInView={{scale:1, x: 0, opacity: 1 }} 
                transition={{ delay: 0 }}
                exit={{
                  opacity: 0, 
                  transform: 'scale(1.5)', 
                  transition: { ease: 'easeIn', duration: 100 } 
                }} className="m-10">
        <h1 className="text-4xl text-center font-bold text-gray-800 mb-5">About Guru Kripa Consultants</h1>
        <p className="text-xl font-normal text-center text-justify">Guru Kripa Consultants offers effective Vacuum Pumping Solutions and Services. We look forward to supporting you for all your vacuum pumping requirements. We are Extensively working together for Understanding the Customer Problems related to Vacuum Pumps, Water and Utility wastage, Low Maintenance Period and Solving them with adequate Solution at Minimum time Period. We are also a Leading Supplier of Spares & Service for the other well known Brands on Competitive Prices. Guru Kripa Consultants was Started on an Idea for Providing Single Shop Solution on a Competitive Prices with best Quality Products.</p>
      </m.div>
      <m.div ref={ref} style={{scale:0,opacity:1 }} className="m-0 p-10" InView={{ x: 10, opacity: 0 }}
                whileInView={{scale:1, x: 0, opacity: 1 }} 
                transition={{ delay: 0 }}
                exit={{
                  opacity: 0, 
                  transform: 'scale(1.5)', 
                  transition: { ease: 'easeIn', duration: 100 } 
                }} className="m-10">
      <h1 className="text-4xl text-center font-bold text-gray-800 mb-5">Our Vision:</h1>
      <p className="text-xl font-normal text-center">To Support our Prestigious Clients with Low Cost and Highly Efficient Vacuum Solutions.</p>
      </m.div>
    </div>
    </LazyMotion>
  )
}

export default About