import './App.css';
import {BrowserRouter as Router,Routes,Route,Navigate,} from "react-router-dom"
import Home from "./pages/Home"
import About from "./pages/About"
import Services from "./pages/Services"
import Contact from "./pages/Contact"
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/About" element={<About/>}/>
          <Route exact path="/Services" element={<Services/>}/>
          <Route exact path="/Contact" element={<Contact/>}/>
      </Routes>
      </Router>
    </div>
  );
}

export default App;
