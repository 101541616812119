import React from 'react'
import E2M from "../img/services/E2M Double Stage Oil vane pump Catalogue-3.png"
import  E2S from "../img/services/E2S Double Stage Oil vane pump Catalogue-3.png"
import  EDP from "../img/services/EDP Chemical Dry Claw Catalogue-4.png"
import  EDS from "../img/services/EDS Chemical Dry Screw Catalogue-3.png"
import  LR1A from "../img/services/Edwards LR1A Liquid Ring Vacuum Pump Catalogue-26.png"
import  LR1CLiquid from "../img/services/Edwards LR1C Liquid Ring Vacuum Pump Catalogue-09.png"
import  LR1CMonoblock from "../img/services/Edwards LR1C Monoblock Liquid Ring Vacuum Pump Catalogue-1.png"
import  Edwardsmicrovac from "../img/services/Edwards stokes microvac rotary piston pumps Catalogue-2.png"
import  EdwardsVacuum from "../img/services/Edwards Stokes Vacuum Booster Catalogue-1.png"
import  EH from "../img/services/EH Vacuum Booster Catalogue-1.png"
import  ERV from "../img/services/ERV Single Stage Oil Vane Pump Catalogue-3.png"
import  NEDC from "../img/services/NEDC & EDC Single Stage Dry Claw Catalogue-4.png"
import NES from "../img/services/nES Single Stage Oil vane pump Catalogue-12.png"
import NXDS from "../img/services/nXDS Scroll Pump Catalogue-11.png"
import XDS from "../img/services/XDS35i Scroll  Pump catalogue-1.png"
import { MdDoubleArrow } from "react-icons/md";
import { Card } from "flowbite-react";
import { AnimatePresence,motion,useMotionValue,useTransform, } from "framer-motion";
import { LazyMotion, domAnimation, m } from "framer-motion"

const responsive = {

  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
const Services = () => {
  const x = useMotionValue(0), y = useMotionValue(0)
  const background = useTransform(
    x,
    [-100, 0, 100],
    ["#d0ffc4", "#fff", "#e7ff60"]
  )
  return (
    <motion.div style={{ background }}>
      <motion.div
        drag="x"
        dragConstraints={{ left: 0, right: 0 }}
        style={{ x }}>
        
      
    <div className="flex-row border-solid border-1 border-indigo-200 mb-8 p-5">
    <h1 className=" text-4xl text-center font-bold text-gray-800 ">We offer Spares & Services</h1>

      <motion.ul className="list-disc tracking-tight text-2xl p-10">
      <AnimatePresence>
      <motion.li initial={{ x: 90000, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }} transition={{ delay: 1 }}
                exit={{
                  opacity: 0, 
                  transform: 'scale(0.8)', 
                  transition: { ease: 'easeIn', duration: 100 } 
                }}>Multi Stage Dry Claw Pump</motion.li>
        <motion.li initial={{ x: 500, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }} transition={{ delay: 2 }}
                exit={{
                  opacity: 0, 
                  transform: 'scale(0.5)', 
                  transition: { ease: 'easeIn', duration: 100 } 
                }}>Single Stage Dry Claw Pump</motion.li>
        <motion.li initial={{ x: 500, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }} transition={{ delay: 3 }}
                exit={{ opacity: 0 }}>Dry Screw Pump</motion.li>
        <motion.li initial={{ x: 500, opacity: 0 }} 
                animate={{ x: 0, opacity: 1 }} transition={{ delay: 4 }}
                exit={{ opacity: 0 }}>Mechanical Vacuum Booster</motion.li>
        <motion.li initial={{ x: 500, opacity: 0 }} 
                animate={{ x: 0, opacity: 1 }} transition={{ delay: 5 }}
                exit={{ opacity: 0 }}>Single Stage Oil Sealed Rotary Vane Vacuum Pump</motion.li>
        <motion.li initial={{ x: 500, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }} transition={{ delay: 6 }}
                exit={{ opacity: 0 }}>Double Stage Oil Sealed Rotary Vane Vacuum Pump</motion.li>
        <motion.li initial={{ x: 500, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }} transition={{ delay: 7 }}
                exit={{ opacity: 0 }}>Piston Pump</motion.li>
        <motion.li initial={{ x: 500, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }} transition={{ delay: 8 }}
                exit={{ opacity: 0 }}>Watering Vacuum Pump</motion.li>
        </AnimatePresence>
      </motion.ul>

</div>
</motion.div>
    </motion.div>
  )
}

export default Services